import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'

const Hero = styled.div`
  position: relative;
  background: #000;
  color: #fff;
  text-align: center;
`

const HeroImage = styled(Img)`
  height: 61.8vh;
  max-height: 400px;
`

const ShareContainer = styled.div`
  text-align: end;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  button {
    padding: 4px !important;
  }
`

export default ({ data, location }) => (
  <Hero>
    <ShareContainer>
      <FacebookShareButton url={location.origin} children={<FacebookIcon size={32} round={true} />} />
      <TwitterShareButton title={data.name} url={location.origin} children={<TwitterIcon size={32} round={true} />} />
      <TelegramShareButton title={data.name} url={location.origin} children={<TelegramIcon size={32} round={true} />} />
      <WhatsappShareButton title={data.name} url={location.origin} children={<WhatsappIcon size={32} round={true} />}  />
      <EmailShareButton subject={data.name} url={location.origin} children={<EmailIcon size={32} round={true} />} />
    </ShareContainer>
    <HeroImage alt={data.name} fluid={data.heroImage.fluid} />
  </Hero>
)
